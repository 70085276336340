/** @jsx jsx */
/* eslint-disable no-unused-vars */
import  React from 'react';
import { jsx } from 'theme-ui';
import Layout from './../components/Layout';
import data from '../../dashboards.json'
import headerImg from '../../static/dashboard.svg';
import '../styles/main.scss';

const IndexPage = () => {
  const revData = data.reverse();

  return (
    <Layout maxWidth="1440px">
      <header sx={{display:'grid', gridTemplateColumns:['1fr','1fr 1fr'],mb:12}}>
        <div sx={{display:'flex', alignItems:'center', justifyContent:'center'}}>
            <h2 sx={{fontSize:[5,null,6],p:[4,6], textAlign:'center'}}>At Factly, our endeavour has always been to make public data meaningful not just by using numbers & charts, but also by providing the necessary knowledge & context about issues. Each of the dashboards here not only has relevant data & analysis but also gives necessary information</h2>
        </div>
        
        <div sx={{display:'flex', alignItems:'center', justifyContent:'center'}}><img src={headerImg} alt="" sx={{width:'full',p:[4,6]}}/></div>
      </header>
      
        <div className="row" sx={{gridTemplateColumns:['1fr','1fr 1fr','1fr 1fr 1fr'], gridGap:["0.25rem","0.5rem",'1rem','1.5rem']}}>
          {revData.map((item, i) =>{ 
            return (
            <a href={item.path} className="card" key={i}>
              <div className="card-content" style={{ backgroundImage: `url( ${item.img ?item.img:`/images/r3.jpg`})` }}>
                <div className='card-info' sx={{bg:['rgba(234, 54, 74,0.75)','primary']}}>
                  <h1 className="card-title">{item.title}</h1>
                  <p className="card-description">{item.desc}</p>
                  <button className="btn">View Dashboard</button>
                </div>
              </div>
            </a>

          )})}
        </div>
    </Layout>
  );
};

export default IndexPage;
